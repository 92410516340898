/* Index page */

.main-container {
  margin: auto;
  max-width: 1048px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}

.integration-list {
  display: flex;
  margin-top: 6%;
  max-width: 1048px;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .integration-list {
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
}

@media (min-width: 1024px) {
  .integration-list {
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
}

.integration-list-item {
  background: #f7f8f9;
  border-radius: 6px;
  flex: 1 1 0;
  margin: 4px;
  min-width: 40%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f7f8f9;
}

.integration-list-item:hover {
  box-shadow: 0 16px 24px 0 #e5eaef;
  text-decoration: none;
  border: 1px solid #06f;
}

@media (min-width: 768px) {
  .integration-list-item {
    margin-left: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
    margin-top: 16px;
    min-width: 25%;
  }
}

.integration-list-item-link {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .integration-list-item-link {
    padding-left: 28px;
    padding-bottom: 28px;
    padding-right: 28px;
    padding-top: 28px;
  }
}

.integration-list-item-title {
  margin: 0;
  text-align: center;
  color: #00112c;
  font-size: 1em;
  font-weight: 700;
  margin: 10px 0 0;
}

@media (min-width: 768px) {
  .integration-list-item-title {
    font-size: 24px;
    margin-left: 0;
    margin-bottom: 6px;
    margin-right: 0;
  }
}

.integration-list-item-subtitle {
  color: #00112c;
  font-size: 0.67em;
  font-weight: 700;
  margin: 10px 0 0;
}

@media (min-width: 768px) {
  .integration-list-item-subtitle {
    font-size: 16px;
    margin-left: 0;
    margin-bottom: 6px;
    margin-right: 0;
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  margin-top: 10%;
  color: #00112c;
}

/* end Index page */

/* Cart preview page */

.shopping-cart {
  float: right;
}
@media (min-width: 768px) {
  .shopping-cart {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 3px;
  }
}
.shopping-cart-link {
  display: inline-block;
  position: relative;
}
.order-summary-list {
  border-top: 1px solid #e6e9eb;
}
.order-summary-list-list-item {
  border-bottom: 1px solid #e6e9eb;
  display: flex;
  height: 97px;
}
.order-summary-list-list-item-image {
  height: 64px;
  margin: 16px;
  width: 64px;
}
.order-summary-list-list-item-title {
  font-weight: 700;
  margin: auto auto auto 0;
}
.order-summary-list-list-item-price {
  color: #687282;
  margin: auto 16px;
  text-align: right;
  width: 80px;
}
@media (min-width: 768px) {
  .order-summary-list-list-item-price {
    margin-left: 24px;
    margin-bottom: auto;
    margin-right: 24px;
    margin-top: auto;
  }
}
.order-summary-list-list-item-remove-product {
  background: none;
  border: 0;
  cursor: pointer;
  height: 25px;
  margin: auto 0;
  padding: 0;
  width: 25px;
}
.cart {
  text-align: center;
  margin-top: 80px;
}
.cart-footer {
  font-weight: 700;
  margin-top: 17px;
  text-align: right;
}
@media (min-width: 768px) {
  .cart-footer {
    margin-top: 24px;
  }
}
.cart-footer .button {
  margin-top: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .cart-footer .button {
    margin-top: 0;
    width: 288px;
  }
}
.cart-footer-amount {
  margin-left: 16px;
  margin-right: 24px;
}
.whole-preview {
  margin: auto;
  max-width: 1110px;
  padding: 0 16px;
}
@media (min-width: 1440px) {
  .whole-preview {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

/* end of Cart preview page */

/* Payment page */

#payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#payment-page .container {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  padding-left: 8px;
  padding-right: 8px;
}

.checkout-component {
  background: #f7f8f9;
  border: 1px solid #e6e9eb;
  border-radius: 12px;
  margin: 8px 0;
}

/* Adyen Components */
.payment {
  width: 100%;
  padding-top: 0px !important;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 1076px) {
  #payment-page .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payment {
    align-self: center;
    max-width: 610px;
  }
}

.payment-container {
  display: flex;
  justify-content: center;
  background: #f7f8f9;
  border: 1px solid #e6e9eb;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  height: 100%;
}

/* end Payments page */

/* Dropin page */

#dropin {
  width: 100%;
  max-width: 450px;
}

@media screen and (max-width: 1076px) {
  #dropin {
    width: 100%;
    max-width: 610px;
  }
}

/* end Dropin page */

/* Status page */

.status-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  margin: 100px 0 126px;
  text-align: center;
}

.status .status-image {
  display: block;
  height: 100px;
  margin: 16px auto 0;
}

.status .status-image-thank-you {
  height: 66px;
}

.status .status-message {
  margin: 8px 0 24px;
}

.status .button {
  max-width: 236px;
}

@media (min-width: 768px) {
  .status .button {
    max-width: 200px;
  }
}

/* end Status page */
